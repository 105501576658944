<template>
  <div>
    <v-toolbar
        dark
        color="#00004d"
    >
      <v-toolbar-title class="pl-0">
        <span v-html="item.hk"></span>
      </v-toolbar-title>
    </v-toolbar>
    <v-tabs v-model="activeTabDetail" background-color="#002366" left dark>
      <v-tabs-slider></v-tabs-slider>
      <v-tab>Details</v-tab>
      <v-tab>Description</v-tab>
      <v-tab>Location</v-tab>
      <v-tab>Social Media Links</v-tab>
      <v-tab>Media</v-tab>
    </v-tabs>
    <v-tabs-items touchless v-model="activeTabDetail">
      <v-tab-item>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="3">
              <h6>Title:</h6>
              <p v-html="item.title"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Type:</h6>
              <p v-html="item.project_type ? item.project_type.title : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Project Number:</h6>
              <p v-html="item.project_no ? item.project_no : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Completion Status:</h6>
              <p v-html="item.completion_status ? item.completion_status.title : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Life Cycle:</h6>
              <p v-html="item.life_cycle ? item.life_cycle.title : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Master Development:</h6>
              <p v-html="item.development ? item.development.commercial_title : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Developer:</h6>
              <p v-html="item.developer ? item.developer.full_name : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Plot Number:</h6>
              <p v-html="item.plot_no ? item.plot_no : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Project Configuration:</h6>
              <p v-html="item.configuration ? item.configuration : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Number Of Units:</h6>
              <p v-html="item.no_of_units ? item.no_of_units : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Number Of Sold Units:</h6>
              <p v-html="item.no_of_sold_units ? item.no_of_sold_units : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Number Of Remaining Units:</h6>
              <p v-html="item.no_of_remaining_units ? item.no_of_remaining_units : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Number Of Floor:</h6>
              <p v-html="item.no_of_floor ? item.no_of_floor : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Plot Area:</h6>
              <p v-html="item.plot_area ? item.plot_area + ' (' + item.plot_area_unit + ')' : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Built Up Area:</h6>
              <p v-html="item.built_up_area ? item.built_up_area + ' (' + item.built_up_area_unit + ')' : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Status Percentage:</h6>
              <p v-html="item.status_percentage ? item.status_percentage : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Launch Date:</h6>
              <p v-html="item.launch_date ? formatMomentDate(item.launch_date) : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Registration Date:</h6>
              <p v-html="item.registration_date ? formatMomentDate(item.registration_date) : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Construction Started Date:</h6>
              <p v-html="item.construction_started_date ? formatMomentDate(item.construction_started_date) : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Anticipated Completion Date:</h6>
              <p v-html="item.anticipated_completion_date ? formatMomentDate(item.anticipated_completion_date) : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Handover Date:</h6>
              <p v-html="item.handover_date ? formatMomentDate(item.handover_date) : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Main Contractors:</h6>
              <p v-html="item.main_contractor_titles ? item.main_contractor_titles : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Sub Contractors:</h6>
              <p v-html="item.sub_contractor_titles ? item.sub_contractor_titles : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Architects:</h6>
              <p v-html="item.architect_titles ? item.architect_titles : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Cost Consultants:</h6>
              <p v-html="item.cost_consultant_titles ? item.cost_consultant_titles : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Piling Contractors:</h6>
              <p v-html="item.piling_contractor_titles ? item.piling_contractor_titles : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>MEP Contractors:</h6>
              <p v-html="item.mep_contractor_titles ? item.mep_contractor_titles : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Project Managers
                :</h6>
              <p v-html="item.project_manager_titles ? item.project_manager_titles : '-'"></p>
            </v-col>
            <v-col cols="12">
              <h6>Special Classifications:</h6>
              <p v-html="item.special_classification_titles ? item.special_classification_titles : '-'"></p>
            </v-col>
            <v-col cols="12">
              <h6>Amenities:</h6>
              <p v-html="item.amenity_titles ? item.amenity_titles : '-'"></p>
            </v-col>
            <v-col cols="12">
              <h6>Road Locations:</h6>
              <p v-html="item.road_location_titles ? item.road_location_titles : '-'"></p>
            </v-col>
            <v-col cols="12">
              <h6>Views:</h6>
              <p v-html="item.view_titles ? item.view_titles : '-'"></p>
            </v-col>
          </v-row>
        </v-card-text>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <p v-html="item.description ? item.description : '-'"></p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="4">
                <h6>Location:</h6>
                <p v-html="item.location ? item.location : '-'"></p>
              </v-col>
              <v-col cols="12" sm="4">
                <h6>Latitude:</h6>
                <p v-html="item.latitude ? item.latitude : '-'"></p>
              </v-col>
              <v-col cols="12" sm="4">
                <h6>Longitude:</h6>
                <p v-html="item.longitude ? item.longitude : '-'"></p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12">
                <gmap-map
                    :zoom="20"
                    :center="center"
                    style="width:100%;  height: 400px;"
                    :clickable="true"
                >
                  <gmap-marker
                      :key="index"
                      v-for="(m, index) in locationMarkers"
                      :position="m.position"
                  ></gmap-marker>
                </gmap-map>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-row v-if="item.hasOwnProperty('youtube_videos_links') && item.youtube_videos_links.length > 0">
              <v-col cols="12" class="pb-0">
                <h6>Youtube Videos:</h6>
              </v-col>
              <v-col cols="12" sm="3" v-for="(link, index) in item.youtube_videos_links" :key="index">
                <v-card class="mb-1">
                  <v-card-text>
                    <p class="mb-1" v-html="link.title"></p>
                    <a target="_blank"
                       :href="link.url"
                       v-html="link.url"></a>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row v-if="item.hasOwnProperty('youtube_playlist_links') && item.youtube_playlist_links.length > 0">
              <v-col cols="12" class="pb-0">
                <h6>Youtube Playlist:</h6>
              </v-col>
              <v-col cols="12" sm="3" v-for="(link, index) in item.youtube_playlist_links" :key="index">
                <v-card class="mb-1">
                  <v-card-text>
                    <p class="mb-1" v-html="link.title"></p>
                    <a target="_blank"
                       :href="link.url"
                       v-html="link.url"></a>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row v-if="item.hasOwnProperty('virtual_tour_links') && item.virtual_tour_links.length > 0">
              <v-col cols="12" class="pb-0">
                <h6>Virtual Tour:</h6>
              </v-col>
              <v-col cols="12" sm="3" v-for="(link, index) in item.virtual_tour_links" :key="index">
                <v-card class="mb-1">
                  <v-card-text>
                    <p class="mb-1" v-html="link.title"></p>
                    <a target="_blank"
                       :href="link.url"
                       v-html="link.url"></a>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row v-if="item.hasOwnProperty('website_urls') && item.website_urls.length > 0">
              <v-col cols="12" class="pb-0">
                <h6>Website URLS:</h6>
              </v-col>
              <v-col cols="12" sm="3" v-for="(link, index) in item.website_urls" :key="index">
                <v-card class="mb-1">
                  <v-card-text>
                    <p class="mb-1" v-html="link.title"></p>
                    <a target="_blank"
                       :href="link.url"
                       v-html="link.url"></a>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row v-if="item.hasOwnProperty('instagram_urls') && item.instagram_urls.length > 0">
              <v-col cols="12" class="pb-0">
                <h6>Instagram URLS:</h6>
              </v-col>
              <v-col cols="12" sm="3" v-for="(link, index) in item.instagram_urls" :key="index">
                <v-card class="mb-1">
                  <v-card-text>
                    <p class="mb-1" v-html="link.title"></p>
                    <a target="_blank"
                       :href="link.url"
                       v-html="link.url"></a>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row v-if="item.hasOwnProperty('facebook_urls') && item.facebook_urls.length > 0">
              <v-col cols="12" class="pb-0">
                <h6>Facebook URLS:</h6>
              </v-col>
              <v-col cols="12" sm="3" v-for="(link, index) in item.facebook_urls" :key="index">
                <v-card class="mb-1">
                  <v-card-text>
                    <p class="mb-1" v-html="link.title"></p>
                    <a target="_blank"
                       :href="link.url"
                       v-html="link.url"></a>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row v-if="item.hasOwnProperty('twitter_urls') && item.twitter_urls.length > 0">
              <v-col cols="12" class="pb-0">
                <h6>Twitter URLS:</h6>
              </v-col>
              <v-col cols="12" sm="3" v-for="(link, index) in item.twitter_urls" :key="index">
                <v-card class="mb-1">
                  <v-card-text>
                    <p class="mb-1" v-html="link.title"></p>
                    <a target="_blank"
                       :href="link.url"
                       v-html="link.url"></a>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row v-if="item.hasOwnProperty('linkedin_urls') && item.linkedin_urls.length > 0">
              <v-col cols="12" class="pb-0">
                <h6>Linkedin URLS:</h6>
              </v-col>
              <v-col cols="12" sm="3" v-for="(link, index) in item.linkedin_urls" :key="index">
                <v-card class="mb-1">
                  <v-card-text>
                    <p class="mb-1" v-html="link.title"></p>
                    <a target="_blank"
                       :href="link.url"
                       v-html="link.url"></a>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-tabs
            v-model="activeTabDetailInner"
            background-color="primary" left dark>
          <v-tab>Master Plan</v-tab>
          <v-tab>Gallery</v-tab>
          <v-tab>Brochure</v-tab>
<!--          <v-tab>Documents</v-tab>-->
        </v-tabs>
        <v-tabs-items touchless v-model="activeTabDetailInner">
          <v-tab-item>
            <v-carousel
                height="auto"
                :continuous="false" :cycle="false" hide-delimiters
                v-if="item.hasOwnProperty('master_plan_files') && item.master_plan_files.length > 0">
              <v-carousel-item
                  v-for="(item,i) in item.master_plan_files"
                  :key="i"
              >
                <v-img
                    contain max-height="500"
                    v-if="checkFileExtension(item.extension)"
                    :src="item.file_public_url"
                ></v-img>

                <v-card height="500"
                        v-else>
                  <iframe
                      onload="this.style.height = this.contentWindow.document.body.scrollHeight + 'px'"
                      style='height: 100%; width: 100%;' frameborder="0" scrolling="no"
                      :src='item.file_public_url' allowfullscreen></iframe>
                </v-card>
              </v-carousel-item>
            </v-carousel>
          </v-tab-item>
          <v-tab-item>
            <Gallery v-if="item.hasOwnProperty('gallery_files') && item.gallery_files.length > 0" :items="item.gallery_files"/>
          </v-tab-item>
          <v-tab-item>
            <v-carousel
                height="auto"
                :continuous="false" :cycle="false" hide-delimiters
                v-if="item.hasOwnProperty('brochure_files') && item.brochure_files.length > 0">
              <v-carousel-item
                  v-for="(item,i) in item.brochure_files"
                  :key="i"
              >
                <v-img
                    contain max-height="500"
                    v-if="checkFileExtension(item.extension)"
                    :src="item.file_public_url"
                ></v-img>

                <v-card height="500" v-else>
                  <iframe
                      onload="this.style.height = this.contentWindow.document.body.scrollHeight + 'px'"
                      style='height: 100%; width: 100%;' frameborder="0" scrolling="no"
                      :src='item.file_public_url' allowfullscreen></iframe>
                </v-card>
              </v-carousel-item>
            </v-carousel>
          </v-tab-item>
          <v-tab-item>
            <v-carousel
                height="auto"
                :continuous="false" :cycle="false" hide-delimiters
                v-if="item.hasOwnProperty('document_files') && item.document_files.length > 0">
              <v-carousel-item
                  v-for="(item,i) in item.document_files"
                  :key="i"
              >
                <v-img
                    contain max-height="500"
                    v-if="checkFileExtension(item.extension)"
                    :src="item.file_public_url"
                ></v-img>

                <v-card height="500" v-else>
                  <iframe
                      onload="this.style.height = this.contentWindow.document.body.scrollHeight + 'px'"
                      style='height: 100%; width: 100%;' frameborder="0" scrolling="no"
                      :src='item.file_public_url' allowfullscreen></iframe>
                </v-card>
              </v-carousel-item>
            </v-carousel>
          </v-tab-item>
        </v-tabs-items>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import ProjectUnitDetails from "@/view/pages/realstate/projects/inc/ProjectUnitDetails";
import ProjectUnitDetailsPm from "@/view/pages/realstate/projects/inc/ProjectUnitDetailsPm";
import ProjectTypicalFloorPlans from "@/view/pages/realstate/projects/inc/ProjectTypicalFloorPlans";
import ProjectInternalLayouts from "@/view/pages/realstate/projects/inc/ProjectInternalLayouts";
import ProjectExternalLayouts from "@/view/pages/realstate/projects/inc/ProjectExternalLayouts";
import Gallery from "@/view/pages/realstate/media/Gallery";
export default {
  name: 'report-project-details',
  components: {
    Gallery,
    ProjectUnitDetails,
    ProjectUnitDetailsPm,
    ProjectTypicalFloorPlans,
    ProjectInternalLayouts,
    ProjectExternalLayouts
  },
  props: ['item'],
  data() {
    return {
      center: {},
      locationMarkers: [],
    }
  },
  watch: {
    item() {
      this.activeTabDetail = 0
      this.activeTabDetailInner = 0
      this.item.latitude = this.item.latitude ? parseFloat(this.item.latitude) : 0;
      this.item.longitude = this.item.longitude ? parseFloat(this.item.longitude) : 0;
      const marker = {
        lat: this.item.latitude ? parseFloat(this.item.latitude) : 0,
        lng: this.item.longitude ? parseFloat(this.item.longitude) : 0
      };
      this.locationMarkers.push({position: marker});
      this.center = marker;
    }
  },
  mounted() {
    this.item.latitude = this.item.latitude ? parseFloat(this.item.latitude) : 0;
    this.item.longitude = this.item.longitude ? parseFloat(this.item.longitude) : 0;
    const marker = {
      lat: this.item.latitude ? parseFloat(this.item.latitude) : 0,
      lng: this.item.longitude ? parseFloat(this.item.longitude) : 0
    };
    this.locationMarkers.push({position: marker});
    this.center = marker;
  },
  methods: {},
  computed: {}
};
</script>