<template>
  <v-container>
    <v-card v-if="vendor_image || vendor_name || vendor_orn || vendor_emails.length > 0 || vendor_phones.length > 0 || vendor_websites.length > 0 || vendor_addresses.length > 0">
      <v-card-text>
        <v-row justify="center" align="center">
          <v-col cols="12" sm="6" class="pb-0 pt-0">
            <img style="width: 40%" :src="vendor_image" alt="Logo" title="Logo">
          </v-col>
          <v-col cols="12" sm="6" class="pb-0 pt-0">
            <div class="float-right">
              <h2 style="color: #000000" v-if="vendor_name">{{vendor_name}}</h2>
              <p v-if="vendor_orn"><strong>ORN:
                {{vendor_orn}}
              </strong></p>
              <p class="custom-item-comma" v-if="vendor_emails.length > 0"><strong>Email(s):
                <a :href="'mailto:' + email" v-for="(email, eind) in vendor_emails" :key="eind">
                  {{ email }}<span class="comma">,</span>
                </a>
              </strong></p>
              <p class="custom-item-comma" v-if="vendor_phones.length > 0"><strong>Phone(s):
                <a :href="'tel:' + phone" v-for="(phone, pind) in vendor_phones" :key="pind">
                  {{ phone }}<span class="comma">,</span>
                </a>
              </strong></p>
              <p class="custom-item-comma" v-if="vendor_websites.length > 0"><strong>Website(s):
                <a :href="'//'+web" target="_blank" v-for="(web, wind) in vendor_websites" :key="wind">
                  {{ web }}<span class="comma">,</span>
                </a>
              </strong></p>
              <p class="custom-item-comma" v-if="vendor_addresses.length > 0"><strong>Address(es):
                <a v-for="(add, aind) in vendor_addresses" :key="aind">
                  {{ add }}<span class="comma">,</span>
                </a>
              </strong></p>
            </div>
          </v-col>
          <!--      <v-card flat>-->
          <!--        <v-card-text>-->
          <!--          <div v-if="vendor_image">-->
          <!--            <img style="width: 20%" :src="vendor_image" alt="Logo" title="Logo">-->
          <!--          </div>-->
          <!--          <div v-if="vendor_emails" class="float-right">-->
          <!--            <img style="width: 20%" :src="vendor_image" alt="Logo" title="Logo">-->
          <!--          </div>-->
          <!--        </v-card-text>-->
          <!--      </v-card>-->
          <!--      <v-col cols="12" sm="12">-->
          <!--        <a href="https://www.afsrealestate.com" target="_blank">-->
          <!--          <img style="width: 100%" src="media/company/header.jpg" alt="Logo" title="AFS Real Estate">-->
          <!--        </a>-->
          <!--      </v-col>-->
        </v-row>
      </v-card-text>
    </v-card>
    <v-divider></v-divider>
    <v-card v-if="detailItem.hasOwnProperty('id')">
      <v-card-text
          v-if="$route.query.location_ref === 'development'">
        <ReportDevelopmentDetails :item="detailItem"/>
      </v-card-text>
      <v-card-text v-if="$route.query.location_ref === 'project'">
        <ReportProjectDetails :item="detailItem"/>
      </v-card-text>
    </v-card>
    <v-row>
      <v-col cols="12" sm="12">
        <b-alert
            show
            variant="light"
            class="alert alert-custom alert-success alert-shadow fade show gutter-b mb-0"
        >
          <div class="alert-text mt-2 text-center">
            <h1 style="color: #000000"><b>Report as per searching criteria selected below:</b></h1>
          </div>
        </b-alert>
      </v-col>
    </v-row>
    <v-card class="card-custom bg-gray-100 card-stretch gutter-b">
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header class="custom-expansion-panel-header" color="#00004d">
            <div class="text-center font-weight-bold">
              Search/Filters
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-form @submit.stop.prevent="hitAdvanceSearch">
              <v-row>
                <v-col cols="12" sm="2">
                  <v-text-field :counter="10"
                                maxLength="10"
                                dense outlined
                                hide-details
                                maxlength="10" v-model="searchFormData.minPriceSearch"
                                label="Min Price"></v-text-field>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-text-field :counter="10"
                                maxLength="10"
                                dense outlined
                                hide-details
                                maxlength="10" v-model="searchFormData.maxPriceSearch"
                                label="Max Price"></v-text-field>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-text-field :counter="10"
                                maxLength="10"
                                dense outlined
                                hide-details
                                maxlength="10" v-model="searchFormData.minBuaSearch"
                                label="Min Size"></v-text-field>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-text-field :counter="10"
                                maxLength="10"
                                dense outlined
                                hide-details
                                maxlength="10" v-model="searchFormData.maxBuaSearch"
                                label="Max Size"></v-text-field>
                </v-col>

                <!--              <v-col cols="12" sm="2">-->
                <!--                <v-text-field :counter="10"-->
                <!--                              maxLength="10"-->
                <!--                              dense outlined-->
                <!--                              hide-details-->
                <!--                              maxlength="10" v-model="searchFormData.minPaSearch"-->
                <!--                              label="Min Plot Area"></v-text-field>-->
                <!--              </v-col>-->
                <!--              <v-col cols="12" sm="2">-->
                <!--                <v-text-field :counter="10"-->
                <!--                              maxLength="10"-->
                <!--                              dense outlined-->
                <!--                              hide-details-->
                <!--                              maxlength="10" v-model="searchFormData.maxPaSearch"-->
                <!--                              label="Max Plot Area"></v-text-field>-->
                <!--              </v-col>-->
                <v-col cols="12" sm="2">
                  <v-autocomplete
                      dense outlined
                      clearable
                      hide-details
                      :items="bedsFilter"
                      v-model="searchFormData.bedSearch"
                      item-text="title"
                      item-value="value"
                      label="Beds"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-autocomplete
                      dense outlined
                      clearable
                      hide-details
                      :items="unitTypesFilterAds"
                      v-model="searchFormData.unitTypeSearch"
                      item-text="title"
                      item-value="value"
                      label="Property Type"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-select
                      dense outlined
                      clearable
                      hide-details
                      :items="priceSortFilter"
                      v-model="searchFormData.priceSortSearch"
                      item-text="title"
                      item-value="value"
                      label="Price Sort"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-select
                      dense
                      outlined
                      clearable
                      hide-details
                      :items="typeSortFilterAds"
                      v-model="searchFormData.typeSearch"
                      item-text="title"
                      item-value="value"
                      label="Type"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-select
                      dense
                      outlined
                      clearable
                      hide-details
                      :items="purposeFilters"
                      v-model="searchFormData.purposeSearch"
                      item-text="title"
                      item-value="value"
                      label="Purpose"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-select
                      dense
                      outlined
                      clearable
                      hide-details
                      :items="fsFilters"
                      v-model="searchFormData.fsSearch"
                      item-text="title"
                      item-value="value"
                      label="Furnishing Status"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-autocomplete
                      dense outlined
                      clearable
                      hide-details
                      :items="rfSortFilterAds"
                      v-model="searchFormData.rent_frequency"
                      item-text="title"
                      item-value="value"
                      label="Rent Frequency"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <button :disabled="loading" type="submit" style="width: 100%"
                          class="btn btn-success theme-btn ml-auto mr-2">
                    Search
                    <v-icon class="ml-2" small>fas fa-search</v-icon>
                  </button>
                </v-col>
                <v-col cols="12" sm="6">
                  <button :disabled="loading" style="width: 100%" @click="resetSearchForm"
                          class="btn btn-primary ml-auto">
                    Reset
                    <v-icon class="ml-2" small>fas fa-times</v-icon>
                  </button>
                </v-col>
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-card-title class="pb-0">
        Advertisements ({{ addTSBVZ(totalRecords) }})
      </v-card-title>
      <v-row>
        <v-col cols="12">
          <v-data-table
              dense
              :headers="headers"
              :items="items"
              fixed-header
              height="500"
              :options.sync="pagination"
              :server-items-length="totalRecords"
              :footer-props="{
                    itemsPerPageOptions: rowsPerPageItems,
                    showFirstLastPage: true,
                  }"
              :loading="loading"
              mobile-breakpoint="100"
              ref="mrc-ads-datatable"
              class="elevation-0 custom-td-table">
            <template v-slot:item.type="{ item }">
              <div class="text-truncate">
                <p v-if="item.type === 'residential'">Residential</p>
                <p v-else>Commercial</p>
              </div>
            </template>
            <template v-slot:item.location="{ item }">
              <div class="text-truncate" v-if="item.project_id">
                <a href="javascript:void(0)">
                  <span v-html="item.location"></span>
                </a>
              </div>
              <div class="text-truncate" v-if="item.p_development && !item.project_id && item.p_development.type === '0'">
                <a href="javascript:void(0)">
                  <span v-html="item.location"></span>
                </a>
              </div>
              <div class="text-truncate" v-if="item.p_development && !item.project_id && item.p_development.type === '1'">
                <a href="javascript:void(0)">
                  <span v-html="item.location"></span>
                </a>
              </div>
              <div class="text-truncate" v-if="!item.development_id && !item.project_id">
                <p>
                  <span v-html="item.location"></span>
                </p>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import {mapState} from "vuex";
import ReportDevelopmentDetails from "@/view/pages/realstate/reports/inc/ReportDevelopmentDetails";
import ReportProjectDetails from "@/view/pages/realstate/reports/inc/ReportProjectDetails";
import {
  REPORT_ADS_LIST,
} from "@/core/services/store/reports.module";

export default {
  name: "ads-report-details",
  components: {
    ReportDevelopmentDetails,
    ReportProjectDetails
  },
  data() {
    return {
      panel: 0,
      headers: [
        {text: "Created", align: ' d-none', value: "created_at", name: "created_at", filterable: false, sortable: true},
        {text: 'Type', value: 'type', name: 'type', filterable: false, sortable: false},
        {text: 'Purpose', value: 'sub_type', name: 'sub_type', filterable: false, sortable: false},
        {text: 'Location', value: 'location', name: 'location', filterable: false, sortable: false},
        // {text: 'Master Development', value: 'development', name: 'development', filterable: false, sortable: false},
        // {text: 'Sub Development 1', value: 'community', name: 'community', filterable: false, sortable: false},
        // {text: 'Sub Development 2/Project', value: 'sub_community', name: 'sub_community', filterable: false, sortable: false},
        // {text: 'Project', value: 'project', name: 'project', filterable: false, sortable: false},
        {text: 'Beds', value: 'beds', name: 'beds', filterable: false, sortable: false},
        {text: 'Baths', value: 'baths', name: 'baths', filterable: false, sortable: false},
        {text: "Price", value: "price", name: "price", filterable: false, sortable: false},
        {text: "Size", value: "size", name: "size", filterable: false, sortable: false},
        {text: "Property Type", value: "property_type", name: "property_type", filterable: false, sortable: false},
        {text: "Furnishing Status", value: "furnishing_status", name: "furnishing_status", filterable: false, sortable: false},
        {text: "Rent Frequency", value: "rent_frequency", name: "rent_frequency", filterable: false, sortable: false},
      ],
      isLoaded: true,
      draw: 1,
      searchQuery: "",
      loading: true,
      pagination: {
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
        sortDesc: "desc"
      },
      totalRecords: 0,
      rowsPerPageItems: [25, 50, 100, 150, 200],
      headerindex: "",
      columns: [],
      items: [],
      titlesFilter: [],
      isTitlesLoaded: false,
      searchFormData: {},
      detailItem: {},
      vendor_image: '',
      vendor_name: '',
      vendor_orn: '',
      vendor_emails: [],
      vendor_phones: [],
      vendor_websites: [],
      vendor_addresses: [],
    };
  },
  watch: {
    params: {
      handler() {
        this.loadData().then(response => {
          this.items = response.items;
          this.totalRecords = response.total;
        });
      },
      deep: true
    }
  },
  computed: {
    params() {
      return {
        ...this.pagination,
        query: this.searchQuery ? this.searchQuery : ''
      };
    },
    ...mapState({
      errors: state => state.reports.errors
    })
  },
  mounted() {
    for (let i = 0; i < this.headers.length; i++) {
      this.columns.push(
          '&columns[' + i + '][data]=' + this.headers[i].value +
          '&columns[' + i + '][name]=' + this.headers[i].name +
          '&columns[' + i + '][searchable]=' + this.headers[i].filterable +
          '&columns[' + i + '][orderable]=' + this.headers[i].sortable +
          '&columns[' + i + '][search][value]=' +
          '&columns[' + i + '][search][regex]=' + false
      );
    }
    this.searchFormData = this.$route.query
  },
  methods: {
    getHeaderIndex(name) {
      let that = this;
      this.headers.filter(function (ele, i) {
        if (name == ele.name) {
          that.headerindex = i;
        }
      });
    },
    loadData() {
      this.loading = true;
      this.isLoaded = true;
      return new Promise((resolve) => {
        let params = this.params;
        params.length = params.itemsPerPage; //set how many records to fetch per page
        params.start =
            params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
        params.draw = this.draw;
        let query = Object.keys(params).map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
        }).join('&');
        let searchFormDataQuery = Object.keys(this.searchFormData).map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(this.searchFormData[key])
        }).join('&');
        if (searchFormDataQuery) query += '&' + searchFormDataQuery;
        this.getHeaderIndex(params.sortBy[0]);
        query +=
            "&search[value]=" +
            params.query +
            "&search[regex]=" +
            false +
            "&order[0][column]=" +
            (params.sortBy.length === 0 ? 0 : this.headerindex) +
            "&order[0][dir]=" +
            (params.sortDesc[0] ? "desc" : "asc");
        this.$store.dispatch(REPORT_ADS_LIST, {q: query, columns: this.columns}).then((res) => {
          this.draw++;
          let items = res.records.data;
          let total = res.records.recordsFiltered;
          if (res.records.detail_item) this.detailItem = res.records.detail_item
          if (res.records.vendor_image) this.vendor_image = res.records.vendor_image
          if (res.records.vendor_name) this.vendor_name = res.records.vendor_name
          if (res.records.vendor_orn) this.vendor_orn = res.records.vendor_orn
          if (res.records.vendor_emails.length > 0) this.vendor_emails = res.records.vendor_emails
          if (res.records.vendor_phones.length > 0) this.vendor_phones = res.records.vendor_phones
          if (res.records.vendor_websites.length > 0) this.vendor_websites = res.records.vendor_websites
          if (res.records.vendor_addresses.length > 0) this.vendor_addresses = res.records.vendor_addresses
          this.loading = !this.loading;
          this.isLoaded = !this.isLoaded;
          resolve({
            items,
            total
          });
        });
      });
    },
    hitAdvanceSearch() {
      this.pagination.page = 1
      this.loadData().then(response => {
        this.items = response.items;
        this.totalRecords = response.total;
      });
    },
    resetSearchForm() {
      this.pagination.page = 1
      this.searchFormData = {};
      this.loadData().then(response => {
        this.items = response.items;
        this.totalRecords = response.total;
      });
    },
  }
};
</script>
